/*
 * This file will be called in the footer right before closing body tag as defer. Will run even when not signed in
 */

checkTimeToNow();
setUpAccordions();



//Check all times and add class for how old
function checkTimeToNow() {
    const checkTimes = document.querySelectorAll('.check-time');

    let colorCode;
    for (let i = 0; i < checkTimes.length; i++) {
        const timeDiffInSecs =
          getTimeDiffInSecs(Date.now(), new Date(checkTimes[i].dataset.checkTime));
        // console.log(timeDiffInSecs);

        colorCode = getTimeDiffCode(timeDiffInSecs);

        if (colorCode) checkTimes[i].classList.add('ct-' + colorCode);
    }
}